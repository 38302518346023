import React from 'react'
import _, { stubFalse } from 'lodash'
import { Table, Label, Icon } from 'semantic-ui-react'
import moment from 'moment'
import ExportJsonExcel from 'js-export-excel'

import Counter from '../helpers/counter'

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key))
      return false
  }
  return true
}

export const porcentaje = (num, total) => {
  return Math.round((num * 100) / total)
}

export const capitalize = (cadena, separador = ' ') => {
  return cadena.split(separador)
    .map(word => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(separador)
}

/**
 * @param formValues Object
 * @param paramsMap Object
 */
export const extraerParametrosBusqueda = (formValues, paramsMap) => {
  return Object.keys(formValues).map(key => {
    if (formValues[key] && formValues[key] !== -1) {
      switch (key) {
        case 'desde':
          return `${paramsMap[key]}=${formValues[key]} 00:00:00`
        case 'hasta':
          return `${paramsMap[key]}=${formValues[key]} 23:59:59`
        default:
          return `${paramsMap[key]}=${formValues[key]}`
      }
    }
  }).filter(item => item)
}

export const extraerOpcionesOperadores =
  operador => ({ key: operador.id, text: operador.nombre, value: operador.id })

export const extraerOpcionesOperadoresExcepciones =
  operador => ({ key: operador.ID, text: operador.NOMBRE, value: operador.ID })

export const extraerOpcionesProveedores =
  proveedor => ({ key: proveedor.id, text: proveedor.nombre, value: proveedor.id })

export const extraerOpcionesClientes =
  cliente => {
    return { key: cliente.id, text: cliente.nombreEmpresa, value: cliente.id }
  }

export const extraerOpcionesUnidades =
  unidad => {
    return { key: unidad.id, text: `${unidad.numeroEconomico} ${unidad.descripcion} `, value: unidad.id }
  }

export const extraerOpcionesRutas =
  ruta => {
    return { key: ruta.id, text: ruta.nombre, value: ruta.id }
  }

export const extraerOpcionesEstructuras =
  estructura => {
    return { key: estructura.id, text: estructura.detalle(), value: estructura.id }
  }

export const extraerOpcionesCausas =
  causa => {
    return { key: causa.id, text: causa.descripcion, value: causa.id }
  }

export const extraerOpcionesEfectos =
  efecto => {
    return { key: efecto.id, text: efecto.descripcion, value: efecto.id }
  }

export const extraerOpcionesInclemencias =
inclemencia => {
  return { key: inclemencia.ID, text: inclemencia.INCLEMENCIA, value: inclemencia.ID }
}

export const extraerOpcionesCondiciones =
condicion => {
  return { key: condicion.ID, text: condicion.CONDICION, value: condicion.ID }
}

export const filtrarClientesFromServicios = (servicios, clientes) => {
  const idsClientes = _.uniq(servicios?.map(servicio => {
    return servicio.idRuta ? servicio.idCliente : -1
  }))

  return clientes.filter(cliente => idsClientes.includes(cliente.id))
    .map(extraerOpcionesClientes)
}

/**
 * Generate the headers of a table from a List of Strings
 * @param {Array} xs List of Strings
 * TODO: Handle Icons, Colspans
 */
export const table_header = (xs) => (
  <Table.Header>
    <Table.Row>
      {xs.map((x, idx) => (<Table.HeaderCell key={idx}>{x}</Table.HeaderCell>))}
    </Table.Row>
  </Table.Header>
)

export const getDias = (state) => {
  const dias = []

  if (state.lunes) { dias.push(1) }
  if (state.martes) { dias.push(2) }
  if (state.miercoles) { dias.push(3) }
  if (state.jueves) { dias.push(4) }
  if (state.viernes) { dias.push(5) }
  if (state.sabado) { dias.push(6) }
  if (state.domingo) { dias.push(7) }

  return dias;
}

export const getStatusServicio = (servicio) => {

  //Si el servicio esta cancelado
  if (servicio.cancelado) {
    return <Label color='grey'><Icon name='cancel' />Servicio cancelado</Label>
  }

  //Si el tiempo inicial es menor a 30min, el servicio no esta confirmado y el operador cuenta con celular // SUMA
  if (moment(servicio.tiempoInicial).diff(moment(), 'minutes') <= 30 && servicio.confirmado === false) {
    if (servicio.celularOperador) {
      return (
        <Label.Group>
          <Label color='red'><Icon name='arrow right' />Servicio sin confirmar</Label>
          <Counter servicio={servicio} />
        </Label.Group>
      )
    } else {
      return (
        <Label.Group>
          <Label color='red'><Icon name='arrow right' />Servicio sin confirmar</Label>
        </Label.Group>
      )
    }
  }

  //Si el tiempo inicial es menor a 30min, el servicio no esta confirmado y no cuenta con celular // ALIADOS
  if (servicio.IdProveedor !== 1 && !servicio.celularOperador) {
    if (moment(servicio.tiempoInicial).diff(moment(), 'minutes') <= 30 && servicio.confirmado === false) {
      return (
        <Label.Group>
          <Label color='red'><Icon name='arrow right' />Servicio sin confirmar</Label>
        </Label.Group>
      )
    }
  }

  //Servicio que ya fue confirmado en la aplicación y que aun no comienza su tiempo de ruta
  if (moment(servicio.tiempoInicial).diff(moment(), 'minutes') > 30 && servicio.terminado === false && servicio.confirmado === true) {
    return <Label color='orange'><Icon name='arrow right' />Servicio Confirmado</Label>
  }

  //Servicio que ya fue confirmado en la aplicación y está dentro de su tiempo de ruta
  if (moment(servicio.tiempoInicial).diff(moment(), 'minutes') <= 30 && servicio.terminado === false && servicio.confirmado === true) {
    return <Label color='green'><Icon name='arrow right' />Operador en ruta</Label>
  }

  //Servicio que ya se dió por terminado en la aplicación por parte del operador
  if (servicio.terminado === true && servicio.confirmado === true) {
    return <Label color='purple'><Icon name='arrow right' />Servicio terminado</Label>
  }

  //Servicio que se asignó a un operador pero que no está dentro del tiempo límite de inicio y no se ha confirmado en la aplicación
  if (moment(servicio.tiempoInicial).diff(moment(), 'minutes') > 30 && servicio.terminado === false && servicio.confirmado === false) {
    return <Label color='blue'><Icon name='arrow right' />Servicio programado</Label>
  }
}

export const getIconoTipoServicio = (servicio) => {
  switch (servicio.modalidad) {
    case 'Empresarial':
      return <Icon circular color='grey' name='bus' />
    case 'Especial':
      return <Icon circular color='violet' name='bus' />
    default:
      return <Icon circular color='black' name='bus' />
  }
}

export const extraerIniciales = (word) => {
  return word
    .split(" ")
    .map(function (w) {
      return w[0]
    })
    .join('')
}

export const isEven = (number) => {
  return parseInt(number) % 2 === 0
}

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}
console.log("v3");
//Tipos de usuarios
export const tipoUsuario = [
  {
    "tipo": "Developer",
    "ids": [1, 4182, 3746, 2810, 3794, 3810, 4079, 3965,4555,4518,],//manuela(4555)
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": true,
      "cambiarPrecios": true,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": true,
      "crearAuditorias": true,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": true, 
      "forzarTransferencia": true,
      "transferir":true,
      "programarServicios": true,
      "reporteApoyos": true,
      "rescatarServicio": true,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": true,
      "verProgramados": true
    }
  },
  {
    "tipo": "Talento humano",
    "ids": [4057, 4112],
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": true,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": true,
      "verProgramados": false
    }
  },
  {
    "tipo": "Nominas",
    "ids": [4239],//Fernanda
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": true,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  },
  {
    "tipo": "Administracion",
    "ids": [4303, 4086, 1445, 3966, 4156, 4315], //Eduardo, Sonia, Fernando, Margarita, Karen, Evelyn
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": true,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": true,
      "verProgramados": false
    }
  },
  {
    "tipo": "Lider logistica",
    "ids": [3690,2617], //Ismael//juan carlos lopez - coordinador de operadores
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": true,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": true,
      "crearAuditorias": true,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": true,
      "forzarTransferencia": true,
      "transferir":true,
      "programarServicios": true, //Programación diaria
      "reporteApoyos": true,
      "rescatarServicio": false, // Cambio solicitado 28/09/2023 de false a true
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": true,
      "verProgramados": true
    }
  },
  {
    "tipo": "Lider operacion",
    "ids": [4321, 4475], //Ximena, Manuel
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": false,
      "forzarTransferencia": true,
      "transferir":true,
      "programarServicios": true,
      "reporteApoyos": true,
      "rescatarServicio": true,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": false,
      "verProgramados": true
    }
  },
  {
    "tipo": "Lider operacion zona",
    "ids": [1605,4688], //Ramon
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": false,
      "forzarTransferencia": true,
      "transferir":true,
      "programarServicios": true,
      "reporteApoyos": true,
      "rescatarServicio": true,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": false,
      "verProgramados": true
    }
  },
  {
    "tipo": "Auxiliar operaciones",
    "ids": [4187,4542,3857,4657,3837 ], //Vanesa Murguía
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": false,
      "forzarTransferencia": false,//En nuevos permisos cambiar a false
      "transferir":true,
      "programarServicios": true,//En nuevos permisos cambiar a true
      "reporteApoyos": true,
      "rescatarServicio": true,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": false,
      "verProgramados": true
    }
  },
  {
    "tipo": "Auxiliar logistica",
    "ids": [3743], //Clara
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": true,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": true,
      "crearAuditorias": true,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": true,
      "forzarTransferencia": false,//En nuevos permisos cambiar a true
      "transferir":false,
      "programarServicios": false,//En nuevos permisos cambiar a false //Programación diaria
      "reporteApoyos": true,
      "rescatarServicio": false,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": true,
      "verProgramados": true
    }
  },
  {
    "tipo": "Coordinador operativo",
    "ids": [4463, 4464,4488,4558], //Antonio, Jose,
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": true,
      "crearIncidentes": true,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": false,
      "verProgramados": true,
    }
  },
  {
    "tipo": "Monitoristas",
    "ids": [3969,4422,4462,3906, 3907,4515,3973,4157,3825,4423,4554,4646,4696,4727],//(4515-VIRIDIANA NOEMI GONZALEZ GOMEZ)
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": true,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": true,
      "desconfirmarBitacoras": true,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": true,
      "rescatarServicio": true,
      "verAuditorias": true,
      "verEsquemas": true,
      "verFacturacion": false,
      "verProgramados":true,

      //permisis anteriores
      // "abrirModalIncidente": true,
      // "ajustarEsquemas": false,
      // "cambiarPrecios": false,
      // "cambioIndividualHorario": true,
      // "cancelarBitacora": true,
      // "consultarPrecios": false,
      // "crearAuditorias": false,
      // "crearIncidentes": true,
      // "desconfirmarBitacoras": true,
      // "editarIncidentes": false,
      // "forzarTransferencia": false,
      // "programarServicios": false,
      // "reporteApoyos": true,
      // "rescatarServicio": true,
      // "verAuditorias": true,
      // "verEsquemas": true,
      // "verFacturacion": false,
      // "verProgramados": true
    }
  },
  {
    "tipo": "Coordinador",
    "ids": [3732, 159, 166, 158, 3780, 3897, 3968, 2617, 4258,
      393, 1596, 3857, 3646, 4032, 4205, 1489, 3797, 4197],
    "permisos": {
      "abrirModalIncidente": false,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": true
    }
  },
  {
    "tipo": "Atencion Clientes",
    "ids": [2624], // Yazmin
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": true,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  },
  {
    "tipo": "InPlantEsp",
    "ids": [4201,4573], //Vanesa 
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": true,
      "cambioIndividualHorario": true,
      "cancelarBitacora": false,
      "consultarPrecios": true,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  },
  {
    "tipo": "InPlant",
    "ids": [3705, 3957, 4027, 4085, 3837, 4266, 4345, 4362, 4367,
    4414, 4430, 4443, 4476,4544], //Alma, Johana, Ana, Laura, Sharon, Cialeth, Lizeth, Rosario, Claudia, Roberto, Martha, Elena, Miriam,ismaelMedina(4544),
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": true,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": true
    }
  },
  {
    "tipo": "Auditores",
    "ids": [3937], // Ramon
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": true,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  },
  {
    "tipo": "Mecanicos",
    "ids": [4298, 4481,4520], // Esau, Hector,oscar alejandro gomez (4520).
    "permisos": {
      "abrirModalIncidente": false,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": true,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": false,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": true,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  },
  {
    "tipo": "Hardware",
    "ids": [4121],
    "permisos": {
      "abrirModalIncidente": true,
      "ajustarEsquemas": false,
      "cambiarPrecios": false,
      "cambioIndividualHorario": false,
      "cancelarBitacora": false,
      "consultarPrecios": false,
      "crearAuditorias": false,
      "crearIncidentes": false,
      "desconfirmarBitacoras": false,
      "editarIncidentes": false,
      "forzarTransferencia": false,
      "transferir":false,
      "programarServicios": true,
      "reporteApoyos": false,
      "rescatarServicio": false,
      "verAuditorias": false,
      "verEsquemas": false,
      "verFacturacion": false,
      "verProgramados": false
    }
  }
]

/* Función que valida si el usuario logueado pertenece a un tipo
de usuario y obtenemos sus permisos */
export const idUsuarios = (tipos, usuario) => {

  let typeUser = '';
  let permisos = '';
  const fechaLogin = moment().format("YYYY-MM-DD")

  for (let tipo of tipos) {
    const idTipo = tipo.ids.includes(usuario.id)

    if (idTipo === true) {
      typeUser = tipo.tipo
      permisos = tipo.permisos

      return (
        usuario.typeUser = typeUser,
        usuario.permisos = permisos,
        usuario.fechaLogin = fechaLogin
      )
    } else {
      typeUser = 'Test';
    }
  }
}

export const validarStorage = (user) => {
  if (user) {
    const data = sessionStorage.getItem('usuario', JSON.stringify(user))
    const hasData = JSON.parse(data);
    return hasData
  }
}

export const downloadExcel = (data, name) => {
  let option = {};
  let dataReporteApoyos = [];
  let numero = 0;

  data.map(d => {
    const fecha = moment(d.fecha_creacion).format('YYYY-MM-DD')
    let hora = ''
    let objApoyos = {}

    if (d.bitacora !== null) {
      if (d.bitacora.HORA_ARRANQUE !== null) {
        hora = d.bitacora.HORA_ARRANQUE.slice(11, 20)
      } else {
        hora = 'Sin dato'
      }
    } else {
      hora = 'Sin dato'
    }

    switch (name) {
      case 'apoyos':
        objApoyos = {
          'no': numero += 1,
          'fecha': fecha,
          'operador': d.operador ? d.operador.NOMBRE : 'Sin dato',
          'bitacora': d.bitacora ? d.bitacora.ID : 'Sin dato',
          'ruta': d.ruta ? d.ruta.NOMBRE : 'Sin dato',
          'hora': hora,
          'unidad': d.operador ? d.operador.ID_UNIDAD : 'Sin dato',
          'comentarios': d.nota ? d.nota : 'Sin dato',
          'capturo': d.usuario ? d.usuario.NOMBRE : 'Sin dato',
        }

        dataReporteApoyos.push(objApoyos)
        break
      default:
        console.log(name);
        break
    }
  })

  switch (name) {
    case 'apoyos':
      option.fileName = 'Reporte Apoyos ' + moment().format('YYYY-MM-DD')

      option.datas = [
        {
          sheetData: dataReporteApoyos,
          sheetName: 'Reporte Apoyos',
          sheetFilter: ['no', 'fecha', 'operador', 'bitacora', 'ruta', 'hora', 'unidad', 'comentarios', 'capturo'],
          sheetHeader: ['No.', 'Fecha', 'Operador', 'Bitacora', 'Ruta', 'Hora', 'Unidad', 'Comentarios', 'Capturó'],
          columnWidths: [2, 4, 14, 4, 15, 4, 3, 18, 14]
        },
      ];
      break
    default:
      console.log(name);
  }

  const toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
}

export const downloadExcelEncuestas = (data, name) => {
  let option = {};
  let dataReporteEncuestas = [];
  let numero = 0;

  data.map(d => {
    const fecha = moment(d.FECHA).format('YYYY-MM-DD')
    let objEncuestas = {}

    let estado_unidad = ''
    let itinerario = ''
    let limpieza_coordinador = ''
    let limpieza_inplant = ''
    let limpieza_operador = ''
    let limpieza_unidades = ''
    let seguridad = ''
    let servicio = ''

    switch (d.ESTADO_UNIDAD) {
      case 1:
        estado_unidad = 'Muy bueno'
        break
      case 2:
        estado_unidad = 'Bueno'
        break
      case 3:
        estado_unidad = 'Regular'
        break
      case 4:
        estado_unidad = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.ITINERARIO) {
      case 1:
        itinerario = 'Muy bueno'
        break
      case 2:
        itinerario = 'Bueno'
        break
      case 3:
        itinerario = 'Regular'
        break
      case 4:
        itinerario = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.LIMPIEZA_COORDINADOR) {
      case 1:
        limpieza_coordinador = 'Muy bueno'
        break
      case 2:
        limpieza_coordinador = 'Bueno'
        break
      case 3:
        limpieza_coordinador = 'Regular'
        break
      case 4:
        limpieza_coordinador = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.LIMPIEZA_IMPLANT) {
      case 1:
        limpieza_inplant = 'Muy bueno'
        break
      case 2:
        limpieza_inplant = 'Bueno'
        break
      case 3:
        limpieza_inplant = 'Regular'
        break
      case 4:
        limpieza_inplant = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.LIMPIEZA_OPERADOR) {
      case 1:
        limpieza_operador = 'Muy bueno'
        break
      case 2:
        limpieza_operador = 'Bueno'
        break
      case 3:
        limpieza_operador = 'Regular'
        break
      case 4:
        limpieza_operador = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.LIMPIEZA_UNIDADES) {
      case 1:
        limpieza_unidades = 'Muy bueno'
        break
      case 2:
        limpieza_unidades = 'Bueno'
        break
      case 3:
        limpieza_unidades = 'Regular'
        break
      case 4:
        limpieza_unidades = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.SEGURIDAD) {
      case 1:
        seguridad = 'Muy bueno'
        break
      case 2:
        seguridad = 'Bueno'
        break
      case 3:
        seguridad = 'Regular'
        break
      case 4:
        seguridad = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.SERVICIO) {
      case 1:
        servicio = 'Muy bueno'
        break
      case 2:
        servicio = 'Bueno'
        break
      case 3:
        servicio = 'Regular'
        break
      case 4:
        servicio = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (name) {
      case 'encuestas':
        objEncuestas = {
          'no': numero += 1,
          'id': d.ID,
          'fecha': fecha,
          'bitacora': !d.BITACORA ? 'Sin información': d.BITACORA,
          'cliente': d.CLIENTE,
          'ruta': d.RUTA,
          'no_empleado': d.NUM_EMPLEADO,
          'nombre': d.NOMBRE,
          'rola_turnos': d.ROLA_TURNOS,
          'conoce_itinerario': d.CONOCE_ITINERARIO,
          'utiliza_mapas': d.UTILIZA_MAPAS,
          'estado_unidad': estado_unidad,
          'itinerario': itinerario,
          'limpieza_coordinador': limpieza_coordinador,
          'limpieza_inplant': limpieza_inplant,
          'limpieza_operador': limpieza_operador,
          'limpieza_unidades': limpieza_unidades,
          'seguridad': seguridad,
          'servicio': servicio,
          'comentarios': d.COMENTARIOS,
        }

        dataReporteEncuestas.push(objEncuestas)
        break
      default:
        console.log(name);
        break
    }
  })

  switch (name) {
    case 'encuestas':
      const cliente = data[0].CLIENTE.trim()
      option.fileName = 'Encuesta ' + moment().format('YYYY-MM-DD')

      option.datas = [
        {
          sheetData: dataReporteEncuestas,
          sheetName: cliente,
          sheetFilter: ['no', 'id', 'fecha', 'bitacora', 'cliente', 'ruta', 'no_empleado', 'nombre', 'rola_turnos', 'conoce_itinerario', 'utiliza_mapas', 'estado_unidad', 'itinerario', 'limpieza_coordinador', 'limpieza_inplant', 'limpieza_operador', 'limpieza_unidades', 'seguridad', 'servicio', 'comentarios'],
          sheetHeader: ['No.', 'Id', 'Fecha', 'Bitacora', 'Cliente', 'Ruta', 'No. Empleado', 'Nombre', 'Rola turnos', 'Conoce Itinerario', 'Utiliza Mapas', 'Estado Unidad', 'Itinerario', 'limpieza Coordinador', 'Limpieza In-plant', 'Limpieza Operador', 'Limpieza Unidad', 'Seguridad', 'Servicio', 'Comentarios'],
          columnWidths: [2, 4, 4, 6, 12, 20, 6, 12, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 20]
        },
      ];
      break
    default:
      console.log(name);
  }
  if(option?.datas[0].sheetName.includes("/"))
  {
    option.datas[0].sheetName = option?.datas[0].sheetName.replace("/", " ");
  }
  console.log("option",option?.datas[0].sheetName);
  
  const toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
}

export const downloadExcelRevisiones = (data, name) => {
  let option = {};
  let dataReporteRevisiones = [];
  let numero = 0;

  data.map(d => {
    const valores = d.attributes
    const fecha = moment(valores.fecha).format('YYYY-MM-DD')
    let objRevisiones = {}

    let limpieza_exterior = ''
    let limpieza_interior = ''
    let tipo_revision = ''

    const operador = d.relations.operadorSuma !== null ? d.relations.operadorSuma.data.attributes.nombre:'Sin dato'
    const usuario = d.relations.usuario !== null ? d.relations.usuario.data.attributes.nombre:'Sin dato'
    const ruta = d.relations.ruta !== null ? d.relations.ruta.data.attributes.nombre:'Sin dato'
    const proveedor = d.relations.proveedor !== null ? d.relations.proveedor.data.attributes.nombre:'Sin dato'

    switch (d.attributes.limpiezaExterior) {
      case 1:
        limpieza_exterior = 'Excelente'
        break
      case 2:
        limpieza_exterior = 'Bueno'
        break
      case 3:
        limpieza_exterior = 'Regular'
        break
      case 4:
        limpieza_exterior = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.attributes.limpiezaInterior) {
      case 1:
        limpieza_interior = 'Excelente'
        break
      case 2:
        limpieza_interior = 'Bueno'
        break
      case 3:
        limpieza_interior = 'Regular'
        break
      case 4:
        limpieza_interior = 'Malo'
        break
      default:
      console.log(name);
      break
    }

    switch (d.attributes.tipoRevision) {
      case 1:
        tipo_revision = 'Entrega de unidad'
        break
      case 2:
        tipo_revision = 'Recepción de unidad'
        break
      case 3:
        tipo_revision = 'Revisión solicitada por Inplant'
        break
      case 4:
        tipo_revision = 'Revisión periódica'
        break
      default:
        tipo_revision = 'Sin información'
      break
    }

    switch (name) {
      case 'revisiones':
        objRevisiones = {
          'no': numero += 1,
          'accionesDerivadas': valores.accionesDerivadas,
          'aforo': valores.aforo === true ? 'Si':'No',
          'asientosReclinables': valores.asientosReclinables === true ? 'Si':'No',
          'botonPanico': valores.botonPanico === true ? 'Si':'No',
          'camaraFatiga': valores.camaraFatiga === true ? 'Si':'No',
          'camaras': valores.camaras === true ? 'Si':'No',
          'cinturonSeguridad': valores.cinturonSeguridad === true ? 'Si':'No',
          'costadoDerecho': valores.costadoDerecho === true ? 'Si':'No',
          'costadoIzquierdo': valores.costadoIzquierdo === true ? 'Si':'No',
          'cristales': valores.cristales === true ? 'Si':'No',
          'defensaDelantera': valores.defensaDelantera === true ? 'Si':'No',
          'defensaParrilla': valores.defensaParrilla === true ? 'Si':'No',
          'defensaTrasera': valores.defensaTrasera === true ? 'Si':'No',
          'dvr': valores.dvr === true ? 'Si':'No',
          'extintor': valores.extintor === true ? 'Si':'No',
          'farosCalaberas': valores.faros === true ? 'Si':'No',
          'faros': valores.faros === true ? 'Si':'No',
          'fecha': fecha,
          'fundasPreferenciales': valores.fundasPreferenciales === true ? 'Si':'No',
          'gatoHidraulico': valores.gatoHidraulico === true ? 'Si':'No',
          'golpes': valores.golpes === true ? 'Si':'No',
          'guiaLetrero': valores.guiaLetrero === true ? 'Si':'No',
          'hologramaEner': valores.hologramaEner === true ? 'Si':'No',
          'hologramaEox': valores.hologramaEox === true ? 'Si':'No',
          'hologramaOxxo': valores.hologramaOxxo === true ? 'Si':'No',
          'kilometraje': valores.kilometraje,
          'kitAuxilio': valores.kitAuxilio === true ? 'Si':'No',
          'kitLimpieza': valores.kitLimpieza === true ? 'Si':'No',
          'letrero': valores.letrero === true ? 'Si':'No',
          'licenciaVigente': valores.licenciaVigente === true ? 'Si':'No',
          'limpiezaExterior': limpieza_exterior,
          'limpiezaInterior': limpieza_interior,
          'limpiezaInteriorBool': valores.limpiezaInteriorBool === true ? 'Si':'No',
          'llanta': valores.llanta === true ? 'Si':'No',
          'llenadoCombustible': valores.llenadoCombustible === true ? 'Si':'No',
          'manivela': valores.manibela === true ? 'Si':'No',
          'neumaticos': valores.neumaticos === true ? 'Si':'No',
          'nota': valores.nota,
          'notaMantenimiento': valores.notaMantenimiento,
          'odometro': valores.odometro === true ? 'Si':'No',
          'operador': operador,
          'parches': valores.parches === true ? 'Si':'No',
          'pantallaControl': valores.pantallaControl === true ? 'Si':'No',
          'polizaSeguros': valores.polizaSeguros === true ? 'Si':'No',
          'portabultos': valores.portabultos === true ? 'Si':'No',
          'proteccionBateria': valores.proteccionBateria === true ? 'Si':'No',
          'proveedor': proveedor,
          'pulidoEncerado': valores.pulidoEncerado === true ? 'Si':'No',
          'rayones': valores.rayones === true ? 'Si':'No',
          'retrovisores': valores.retrovisores === true ? 'Si':'No',
          'rotulosExteriores': valores.rotulosExteriores === true ? 'Si':'No',
          'rotulosInteriores': valores.rotulosInteriores === true ? 'Si':'No',
          'ruta': ruta,
          'tarjetaCirculacion': valores.tarjetaCirculacion === true ? 'Si':'No',
          'tipoRevision': tipo_revision,
          'unidadId': valores.unidadId,
          'unidadLimpia': valores.unidadLimpia === true ? 'Si':'No',
          'uniforme': valores.uniforme === true ? 'Si':'No',
          'usuario': usuario
        }

        dataReporteRevisiones.push(objRevisiones)
        break
      default:
        console.log(name);
        break
    }
  })

  switch (name) {
    case 'revisiones':
      option.fileName = 'Revisiones ' + moment().format('YYYY-MM-DD')

      option.datas = [
        {
          sheetData: dataReporteRevisiones,
          sheetName: 'revision',
          sheetFilter: ['no', 'fecha', 'usuario', 'proveedor', 'operador', 'unidadId', 'ruta', 'kilometraje',
          'tipoRevision', 'gatoHidraulico', 'manivela', 'llanta','kitLimpieza', 'golpes', 'rayones',
          'llenadoCombustible', 'extintor', 'kitAuxilio', 'uniforme', 'fundasPreferenciales', 'cinturonSeguridad',
          'aforo', 'letrero', 'camaras', 'dvr', 'parches', 'botonPanico', 'camaraFatiga', 'asientosReclinables',
          'portabultos', 'tarjetaCirculacion', 'licenciaVigente', 'polizaSeguros', 'hologramaOxxo', 'hologramaEox', 
          'hologramaEner', 'limpiezaExterior', 'unidadLimpia', 'guiaLetrero', 'cristales', 'neumaticos', 'faros',
          'retrovisores', 'defensaParrilla', 'limpiezaInterior', 'limpiezaInteriorBool', 'pantallaControl', 
          'odometro', 'pulidoEncerado', 'defensaDelantera', 'defensaTrasera', 'costadoDerecho', 'costadoIzquierdo',
          'rotulosExteriores', 'rotulosInteriores', 'nota', 'accionesDerivadas', 'notaMantenimiento'
          ],
          sheetHeader: ['No.', 'Fecha', 'Colaborador que registro', 'Proveedor', 'Operador', 'Unidad', 'Ruta', 'Kilometraje',
          'Tipo revision', 'Gato hidraulico', 'Manivela', 'Llanta refacción y cruceta', 'Kit limpieza', 'Golpes', 'Rayones',
          'Gasolina 1/2 tanque', 'Extintor', 'Botiquín', 'Uniforme', 'Asientos fundas preferenciales', 'Cinturon seguridad',
          'Aforo', 'Letrero', 'Camaras', 'DVR', 'Parches QR', 'Boton Panico', 'Camara fatiga', 'Asientos reclinables',
          'Porta bultos', 'Tarjeta circulacion', 'Licencia vigente', 'Poliza seguros', 'Holograma Oxxo', 'Holograma Eox',
          'Holograma Ener', 'Limpieza exterior', 'Unidad limpia y sin ralladuras o raspones', 'Guía de funcionamiento (letrero iluminado)',
          'Cristales en buenas condiciones', 'Neumáticos buenas condiciones', 'Funcionamiento faros y calaveras', 'Retrovisores en buenas condiciones', 
          'Sin golpes defensa o parrilla', 'Limpieza interior', 'Piso, paredes, techo, vidrios, cortinas y asientos limpios y en buenas condiciones',
          'Cuenta con pantalla y control', 'Odómetro funcionando', 'Limpieza, pulido y encerado', 'Defensa delantera', 'Defensa trasera', 'Costado derecho',
          'Costado Izquierdo', 'Rotulos exteriores', 'Rotulos interiores', 'Nota', 'Acciones derivadas a la entrega de unidades', 'Nota mantenimiento',
          ],
          columnWidths: [2, 4, 8, 6, 8, 3, 8, 4, 6, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3,
            3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3, 3, 3, 3, 3, 3, 4, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 15, 15, 15]
        },
      ];
      break
    default:
      console.log(name);
  }

  const toExcel = new ExportJsonExcel(option);
  toExcel.saveExcel();
}


  // {
  //   "tipo": "Programadores",
  //   "ids": [3743, 4187],//Vanessa
  //   "permisos": {
  //     "verFacturacion": false,
  //     "cambiarPrecios": false,
  //     "consultarPrecios": false,
  //     "programarServicios": true,
  //     "ajustarBitacoras": true,
  //     "cancelarBitacora": true,
  //     "desconfirmarBitacoras": true,
  //     "cambioIndividualHorario": true,
  //     "ajustarEsquemas": false,
  //     "verEsquemas": false,
  //     "editarIncidentes": false,
  //     "crearIncidentes": true,
  //     "reporteApoyos": false,
  //     "forzarTransferencia": false,
  //     "verAuditoras": false,
  //     "verProgramados": false
  //   }
  // },